import axios from 'axios'
import { ENV_APP } from '../../../../env'
import api from '../../../api/api'

export default {
  list: async ({ id = null }) => {
    const result = api.get.listReports({ projectId: ENV_APP.ProjectID, id })
    return result
  },

  putField: async ({ id, form }) => {
    const result = api.patch.patchReport(id, form)
    return result
  },

  getFields: async ({ id, fields }) => {
    const result = api.get.getReportFields(id, fields)
    return result
  },

  updateFields: async ({ id, fields }) => {
    const result = api.patch.patchReportFields(id, fields)
    return result
  },

  getLayout: async ({ id }) => {
    const result = axios.get(`layout/${id}`)
    return result
  },

  getReport: async ({ id }) => {
    const result = axios.get(`report/fields/${id}`)
    return result
  },

  getReports: async ({ ids }) => {
    const result = axios.post(`report/fields`, { ids })
    return result
  },

  getValidation: async ({ id }) => {
    const result = axios.get(`validation/${id}`)
    return result
  },

  getHeaders: async ({
    dataInicio,
    dataFim,
    reportTypeId,
    draft = false,
    projectId = ENV_APP.ProjectID,
  }) => {
    const result = axios.get(`/report`, {
      params: {
        DataInicio: dataInicio,
        DataFim: dataFim,
        ReportTypeId: reportTypeId,
        DraftReport: draft,
        ProjectId: projectId,
      },
    })

    return result
  },

  delete: async ({ id }) => {
    const result = axios.delete(`report/${id}`)
    return result
  },

  generatePdf: async html => {
    const result = await axios.post(
      `report/pdf/generate`,
      { html },
      {
        responseType: 'blob',
      },
    )

    return result
  },
  copyReport(data) {
    return axios.post(`/report/copy`, data)
  },
}
