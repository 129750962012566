import Vue from 'vue'
import Vuex from 'vuex'
import actions from './actions'
import getters from './getters'
import report from './modules/report'
import reportType from './modules/reportType'
import structure from './modules/structure'
import mutations from './mutations'
import state from './states'

Vue.use(Vuex)

export default new Vuex.Store({
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
  modules: { structure, reportType, report },
})
