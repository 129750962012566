import { basicInformation, lastVisitAndSignature } from './BasicStructure'
export default class LayoutStructure {
  constructor(layout, id) {
    this.layout = layout
    this.id = id
    this.localStorageKey = `LAYOUT_PDF_${id}`
    this.saveLocalStorage()
  }

  getLayout() {
    return this.layout
  }

  createImageLine(field) {
    return {
      props: 'bt',
      columns: [
        {
          key: field.name,
          type: 'multipleImageSelect',
          label: field.label,
          lines: [],
          props: field.props ?? '',
        },
      ],
    }
  }

  createComponent({ field, structureItem, step, form }) {
    if (field.componentType === 'multipleImageSelect') {
      const imageLine = this.createImageLine(field)

      const lastItemLayout = this.layout[this.layout.length - 1]

      if (lastItemLayout.title == 'Imagens em anexo') {
        lastItemLayout.lines.push(imageLine)
      } else {
        this.layout.push({
          title: 'Imagens em anexo',
          lines: [imageLine],
        })
      }

      return this.saveLocalStorage()
    }

    const selectedStep = structureItem?.id ? structureItem : step

    const transformedField = this.transformField({
      ...field,
      sufixo: form.sufixo,
    })
    const index = this.layout.findIndex(
      item => item.title === selectedStep.title,
    )

    if (index === -1) return

    const item = this.layout[index]
    if (item.lines) {
      this.insertField(item, transformedField, structureItem)
    }

    this.saveLocalStorage()
  }

  createStep({ step, title }) {
    const existStep = this.layout[step - 1]

    if (existStep) {
      const index = this.layout.indexOf(existStep)

      this.layout.splice(index, 0, {
        title,
        lines: [],
      })

      return this.saveLocalStorage()
    }

    this.layout.push({
      title,
      lines: [],
    })

    const imagesStep = this.layout.find(
      item => item.title === 'Imagens em anexo',
    )

    if (imagesStep) {
      const index = this.layout.indexOf(imagesStep)

      this.layout.splice(index, 1)
      this.layout.push(imagesStep)
    }

    this.saveLocalStorage()
  }

  updateStep({ id, stepId, title }) {
    const step = this.layout[Number(id) - 1]

    if (!step) {
      return this.createStep({ step: stepId, title })
    }

    if (id == stepId) {
      step.title = title
      return this.saveLocalStorage()
    }

    step.title = title

    if (stepId !== id) {
      const swapStep = this.layout[Number(stepId) - 1]

      if (swapStep) {
        this.layout.splice(Number(id) - 1, 1, swapStep)
        this.layout.splice(Number(stepId) - 1, 1, step)
      }
    }

    this.saveLocalStorage()
  }

  deleteItem({ step, item }) {
    const keyToRemove = item.name

    if (item.id) {
      const index = this.layout[Number(step.id) - 1]

      if (index === -1) return this.layout

      this.layout.splice(Number(step.id) - 1, 1)

      return this.saveLocalStorage()
    }

    const stepLayout = this.layout[Number(step.id) - 1]

    if (!stepLayout) return

    const newLine = this.removeObjectByKey(stepLayout, keyToRemove)

    if (newLine) {
      this.layout[Number(step.id) - 1] = newLine
    }

    this.saveLocalStorage()
  }

  removeObjectByKey(structure, targetKey) {
    if (Array.isArray(structure)) {
      return structure
        .map(item => this.removeObjectByKey(item, targetKey))
        .filter(item => item !== null)
    } else if (typeof structure === 'object' && structure !== null) {
      const newObject = {}

      for (const [key, value] of Object.entries(structure)) {
        if (key === 'key' && value === targetKey) {
          return null
        }

        newObject[key] = this.removeObjectByKey(value, targetKey)
      }

      for (const key in newObject) {
        if (newObject[key] === null) {
          delete newObject[key]
        }
      }

      if (Array.isArray(newObject.columns) && newObject.columns.length === 0) {
        return null
      }

      return newObject
    }

    return structure
  }

  transformField(field) {
    const transformedField = {
      key: field.name,
      label: field.label || field.name,
      props: field.props ?? 'bt',
      ...(field.sufixo ? { sufixo: field.sufixo } : {}),
    }

    if (field.componentType === 'timePiker') {
      transformedField.props = 'c33'
      transformedField.placeholder = field.placeholder || ''
    } else if (field.componentType === 'datePiker') {
      transformedField.props = 'c33'
      transformedField.placeholder = field.placeholder || ''
    } else if (field.componentType === 'btnAssinatura') {
      transformedField.type = 'signatureWithName'
      transformedField.sufixo = field.sufixo
      transformedField.props = 'c50 center'
    } else if (field.componentType === 'groupRadioButton') {
      transformedField.type = 'signatureWithName'
      transformedField.sufixo = field.fields ? 'nomeProdutor' : 'nomeConsultor'
    } else if (field.componentType === 'multiInsertForm') {
      transformedField.type = 'multiInsertForm'
      transformedField.lineKeys = field.fields.map(field => field.name)
    } else if (field.componentType === 'conform') {
      transformedField.type = 'conform'
      transformedField.lines = []
      transformedField.sufixo = field.sufixo
    } else if (field.componentType === 'groupField') {
      transformedField.type = 'groupField'
      transformedField.lines = []
      transformedField.sufixo = field.sufixo
    }

    const calcRule = field.rules.find(rule => rule.key === 'fieldCalculated')
    if (calcRule) {
      transformedField.type = 'calculated'
      transformedField.calculation = calcRule.value
    }

    return {
      props: 'bt',
      columns: [transformedField],
    }
  }

  insertField(json, newField, structureItem) {
    let inserted = false

    if (structureItem?.id) {
      json.lines.push(newField)
      inserted = true
      return
    }

    const parentKey = structureItem.name

    function recursiveInsert(obj) {
      if (obj.key === parentKey) {
        if (!obj.lines) {
          obj.lines = []
        }

        if (obj.type === 'multiInsertForm' && !obj.lineKeys.length) {
          obj.lineKeys = [newField.columns[0].key]
        }

        obj.lines.push(newField)
        inserted = true
        return true
      }

      if (Array.isArray(obj)) {
        for (let item of obj) {
          if (recursiveInsert(item)) {
            return true
          }
        }
      }

      if (typeof obj === 'object' && obj !== null) {
        if (obj.columns) {
          for (let column of obj.columns) {
            if (column.key === parentKey) {
              if (!column.lines) {
                column.lines = []
              }

              if (
                column.type === 'multiInsertForm' &&
                !column?.lineKeys?.length
              ) {
                column.lineKeys = [newField.columns[0].key]
              } else {
                column.lines.push(newField)
              }

              inserted = true
              return true
            }

            if (column.lines && recursiveInsert(column.lines)) {
              return true
            }
          }
        }

        if (obj.lines && recursiveInsert(obj.lines)) {
          return true
        }
      }

      return false
    }

    recursiveInsert(json)

    if (!inserted) {
      if (json.lines) {
        json.lines.push(newField)
      } else {
        throw new Error('Parent key not found')
      }
    }
  }

  saveLocalStorage() {
    localStorage.setItem(this.localStorageKey, JSON.stringify(this.layout))
  }

  static createStartedLayout() {
    const layout = [basicInformation, lastVisitAndSignature]

    return layout
  }
}
