import Toast from '../../plugins/toast'
import ReportService from '../../services/api/reports'
import ReportTypeService from '../../services/api/reportType'

export const LOAD_REPORT = 'LOAD_REPORT'
export const SET_STATE = 'SET_STATE'
export const LOAD_STRUCTURE = 'LOAD_STRUCTURE'
export const getValue = 'getValue'
export const SET_REPORT = 'SET_REPORT'
export const LOAD_VALIDATION = 'LOAD_VALIDATION'

const state = {
  ready: false,
  data: [],
  structure: [],
  validation: [],
  layout: false,
  id: null,
}

const getters = {
  getValue: state => key => {
    return state.data.find(item => item.key === key)?.value ?? ''
  },

  getReportValue: state => key => {
    return state.data.find(item => item.key === key)
  },

  processRecursively: state => callback => {
    const recursiveFields = (fields, callback) => {
      for (const field of fields) {
        callback(field)

        if (field.fields) {
          recursiveFields(field.fields, callback)
        }

        if (field.fields1) {
          recursiveFields(field.fields1, callback)
        }
      }
    }

    state.structure.steps.forEach(step => {
      recursiveFields(step.fields, callback)
    })
  },
}

const mutations = {
  SET_READY(state, payload) {
    state.ready = payload
  },
  [SET_STATE](state, payload) {
    Object.assign(state, payload)
  },
  SET_DATA(state, payload) {
    state.data = payload
  },
}

const actions = {
  async [LOAD_REPORT]({ commit }, id) {
    commit('SET_READY', true)

    commit('SET_STATE', { id })

    ReportService.getReport({ id })
      .then(response => {
        commit('SET_DATA', response.data)
      })
      .catch(error => {
        Toast().fire({
          icon: 'error',
          title: 'Error ao carregar o relatório: ' + error,
        })
      })
      .finally(() => {
        commit('SET_READY', false)
      })
  },

  async [LOAD_STRUCTURE]({ commit }, id) {
    ReportTypeService.getStructure({ id })
      .then(response => {
        commit('SET_STATE', { structure: response.data })
      })
      .catch(error => {
        Toast().fire({
          icon: 'error',
          title: 'Error ao carregar a estrutura: ' + error,
        })
      })
  },

  async [LOAD_VALIDATION]({ commit }, id) {
    ReportService.getValidation({ id })
      .then(response => {
        commit('SET_STATE', { validation: response.data })
      })
      .catch(error => {
        Toast().fire({
          icon: 'error',
          title: 'Error ao carregar a validação: ' + error,
        })
      })
  },

  async [SET_REPORT]({ commit }, payload) {
    commit('SET_STATE', { data: payload })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
