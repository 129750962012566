/* eslint-disable */
import Swal from 'sweetalert2'
import Toast from '../../plugins/toast'
import ProjectStructureService from '../../services/api/ProjectStructure'
import StructureService from '../../services/api/reportType'
import StructureHistory from '../../services/api/StructureHistory'
import ReportStructure from '../../services/Structure'
import LayoutStructure from '../../services/StructureReport/LayoutStructure'
import ReportService from '../../services/api/reports'
import { ENV_APP } from '../../../env'

export const BOOTSTRAP = 'BOOTSTRAP'
export const LOAD_STRUCTURE = 'LOAD_STRUCTURE'
export const CREATE_REPORT = 'CREATE_REPORT'
export const TREEVIEW = 'TREEVIEW'
export const CREATE_STEP = 'CREATE_STEP'
export const UPDATE_STEP = 'UPDATE_STEP'
export const CREATE_COMPONENT = 'CREATE_COMPONENT'
export const DELETE_ITEM = 'DELETE_ITEM'
export const SET_STATE = 'SET_STATE'
export const SET_STRUCTURE_SELECTED = 'SET_STRUCTURE_SELECTED'
export const GET_FIELD_BY_KEY = 'GET_FIELD_BY_KEY'
export const LOAD_ALL_FIELDS = 'LOAD_ALL_FIELDS'
export const DIFF = 'DIFF'
export const CLEAR_DATA = 'CLEAR_DATA'
export const LOAD_CACHED_STRUCTURE = 'LOAD_CACHED_STRUCTURE'
export const REMOVE_AUTOCOMPLETE_STRUCTURE = 'REMOVE_AUTOCOMPLETE_STRUCTURE'
export const SET_HISTORY_OPENED_ITEMS = 'SET_HISTORY_OPENED_ITEMS'
export const UPDATE_COMPONENT = 'UPDATE_COMPONENT'
export const LOAD_STRUCTURE_HISTORY = 'LOAD_STRUCTURE_HISTORY'
export const UPDATE_STRUCTURE = 'UPDATE_STRUCTURE'
export const ROLLBACK = 'ROLLBACK'
export const COPY_REPORT = 'COPY_REPORT'

const state = {
  data: null,
  initialData: null,
  layoutPdf: {},
  project: {},
  structureSelected: null,
  loading: false,
  isModified: false,
  structuresTypes: [],
  historyOpenedIems: [],
  structuresHistory: [],
  historyLoading: false,
}

const mutations = {
  SET_DATA(state, payload) {
    state.data = payload
  },
  SET_INITIAL_DATA(state, payload) {
    state.initialData = payload
  },
  SET_LAYOUT_PDF(state, payload) {
    state.layoutPdf = payload
  },
  SET_LOADING(state, payload) {
    state.loading = payload
  },
  SET_PROJECT(state, payload) {
    state.project = payload
  },
  SET_STRUCTURE_SELECTED(state, payload) {
    state.structureSelected = payload
  },
  [SET_STATE](state, payload) {
    Object.assign(state, payload)
  },
  SET_MODIFIED_REPORT(state, payload) {
    state.isModified = payload
  },
  SET_HISTORY_OPENED_ITEMS(state, payload) {
    state.historyOpenedIems = payload
  },
  SET_STRUCTURES_HISTORY(state, payload) {
    state.structuresHistory = payload
  },
}

const actions = {
  async [LOAD_STRUCTURE]({ commit, dispatch }, { id = null }) {
    if (!id) return

    commit('SET_LOADING', true)
    commit('SET_STRUCTURE_SELECTED', id)

    const cached = await dispatch(LOAD_CACHED_STRUCTURE, { id })

    if (cached) {
      commit('SET_LOADING', false)
      dispatch(LOAD_STRUCTURE_HISTORY, { id })
      return
    }

    const { data } = await StructureService.findByType({ id })
    const layoutResponse = await StructureService.getLayout({ id })

    const dados = JSON.parse(data.dados)
    const dadosLayout = JSON.parse(layoutResponse.data.dados)

    const key = `DATA_${id}`
    const initialKey = `INITIAL_DATA_${id}`

    localStorage.setItem(key, JSON.stringify(dados))
    localStorage.setItem(initialKey, JSON.stringify(dados))

    commit('SET_DATA', JSON.parse(JSON.stringify(dados)))
    commit('SET_INITIAL_DATA', JSON.parse(JSON.stringify(dados)))
    commit('SET_LAYOUT_PDF', new LayoutStructure(dadosLayout, id))

    dispatch(TREEVIEW)
    dispatch(LOAD_STRUCTURE_HISTORY, { id })
    commit('SET_LOADING', false)
  },

  async [LOAD_CACHED_STRUCTURE]({ commit, dispatch }, { id }) {
    const dataKey = `DATA_${id}`
    const initialDataKey = `INITIAL_DATA_${id}`
    const layoutKey = `LAYOUT_PDF_${id}`

    const exists = localStorage.getItem(dataKey)
    const initialExists = localStorage.getItem(initialDataKey)

    if (!exists || !initialExists) return

    const cachedStructure = JSON.parse(localStorage.getItem(dataKey))
    const initialCached = JSON.parse(localStorage.getItem(initialDataKey))

    const layoutExists = localStorage.getItem(layoutKey)

    commit('SET_DATA', cachedStructure)
    commit('SET_INITIAL_DATA', initialCached)
    commit('SET_LAYOUT_PDF', new LayoutStructure(JSON.parse(layoutExists), id))

    dispatch(TREEVIEW)
    dispatch(DIFF)

    Toast().fire({
      icon: 'success',
      title: 'Estrutura carregada do histórico com sucesso!',
    })

    return cachedStructure
  },

  async [BOOTSTRAP]({ dispatch }) {
    dispatch(LOAD_STRUCTURE)
  },

  async [CREATE_REPORT]({ commit, dispatch }, payload) {
    const {
      reportType: { reportTypes },
    } = this.state

    const id = Math.max(...reportTypes.map(r => r.id)) + 1

    payload.id = id

    const newStructure = ReportStructure.create(payload)

    try {
      const layout = LayoutStructure.createStartedLayout(newStructure)

      await ProjectStructureService.create({
        body: {
          data: newStructure,
          layout,
        },
      })

      dispatch('reportType/LOAD_REPORT_TYPE', null, { root: true })

      Toast().fire({
        icon: 'success',
        title: 'Estrutura criada com sucesso!',
      })
    } catch (error) {
      Toast().fire({
        icon: 'error',
        title: 'Erro ao criar estrutura!',
      })
    }
  },

  async [COPY_REPORT]({ commit, dispatch }, { id = null, title = null }) {
    if (!id) return

    // commit('SET_LOADING', true)

    try {
      const projectId = ENV_APP.ProjectID
      const form = { projectId, id, title}

      const reportCopied = await ReportService.copyReport(form)

      dispatch('reportType/LOAD_REPORT_TYPE', null, { root: true })

      Toast().fire({
        icon: 'success',
        title: 'Estrutura copiada com sucesso!',
      })
    } catch (error) {
      Toast().fire({
        icon: 'error',
        title: 'Erro ao copiar estrutura!',
      })
    }

    // commit('SET_LOADING', false)

  },

  async [TREEVIEW]({ commit }) {
    const copy = JSON.parse(JSON.stringify(state.data))
    const report = jsonToTreeView([copy])
    commit('SET_PROJECT', report)
  },

  async [CREATE_STEP]({ commit, dispatch }, payload) {
    const { title, step } = payload

    const { structure } = getStructureAndLayout(state.structureSelected)

    const newStructure = ReportStructure.createStep({
      title,
      step,
      structure: structure,
    })

    state.layoutPdf.createStep({
      step,
      title,
    })

    saveStructure(state.structureSelected, newStructure)

    commit('SET_DATA', newStructure)
    commit('SET_MODIFIED_REPORT', true)
    dispatch(TREEVIEW)
  },

  async [UPDATE_STEP]({ commit, dispatch }, payload) {
    const { title, step, id } = payload

    const { structure } = getStructureAndLayout(state.structureSelected)

    const newStructure = ReportStructure.updateStep({
      id,
      title,
      stepId: step,
      structure: structure,
    })

    state.layoutPdf.updateStep({
      id,
      stepId: step,
      title,
    })

    saveStructure(state.structureSelected, newStructure)

    commit('SET_DATA', newStructure)
    commit('SET_MODIFIED_REPORT', true)
    dispatch(TREEVIEW)
  },

  async [CREATE_COMPONENT]({ commit, dispatch }, payload) {
    const { component, structureItem, step, form } = payload

    const { structure } = getStructureAndLayout(state.structureSelected)

    const newStructure = ReportStructure.createComponent({
      component,
      structure,
      structureItem,
    })

    state.layoutPdf.createComponent({
      field: component,
      structureItem,
      step,
      form,
    })

    saveStructure(state.structureSelected, newStructure)

    commit('SET_DATA', newStructure)
    commit('SET_MODIFIED_REPORT', true)
    dispatch(TREEVIEW)
  },

  async [UPDATE_COMPONENT]({ commit, dispatch }, payload) {
    const { component, structureItem } = payload

    const key = `DATA_${state.structureSelected}`

    const structure = JSON.parse(localStorage.getItem(key))

    const newStructure = ReportStructure.updateComponent({
      component,
      structure,
      structureItem,
    })

    localStorage.setItem(key, JSON.stringify(newStructure))
    commit('SET_DATA', newStructure)
    commit('SET_MODIFIED_REPORT', true)
    dispatch(TREEVIEW)
  },

  async [DELETE_ITEM]({ commit, dispatch }, payload) {
    const { item, step } = payload

    const { structure } = getStructureAndLayout(state.structureSelected)

    if (!structure) {
      return Toast().fire({
        icon: 'error',
        title: 'Erro ao deletar item!',
      })
    }

    const newReport = ReportStructure.deleteItem({ item, structure })
    state.layoutPdf.deleteItem({
      step,
      item: item,
    })

    saveStructure(state.structureSelected, newReport)

    commit('SET_DATA', newReport)
    commit('SET_MODIFIED_REPORT', true)
    dispatch(TREEVIEW)
  },

  async [GET_FIELD_BY_KEY]({ state }, key) {
    const field = ReportStructure.getFieldByKey({ key, structure: state.data })

    return field
  },

  async [LOAD_ALL_FIELDS]({ state }) {
    const fields = ReportStructure.getAllFields({ structure: state.data })

    return fields
  },

  async [DIFF]({ state, commit }) {
    const result = ReportStructure.isModified({
      initialData: state.initialData,
      data: state.data,
    })

    commit('SET_MODIFIED_REPORT', result)
    return result
  },

  async [CLEAR_DATA]({ commit }) {
    localStorage.removeItem('DATA')
    localStorage.removeItem('INITIAL_DATA')
    commit('SET_DATA', null)
    commit('SET_INITIAL_DATA', null)
    commit('SET_PROJECT', {})
    commit('SET_STRUCTURE_SELECTED', null)
  },

  async [REMOVE_AUTOCOMPLETE_STRUCTURE]({ commit }) {
    commit('SET_PROJECT', {})
    commit('SET_STRUCTURE_SELECTED', null)
  },

  async [LOAD_STRUCTURE_HISTORY]({ commit }, { id }) {
    if (!id) return

    commit('SET_STATE', { historyLoading: true })

    try {
      const { data } = await StructureHistory.getStructureHistory({ id })
      commit('SET_STRUCTURES_HISTORY', data)
    } catch (error) {
      Toast().fire({
        icon: 'error',
        title: 'Erro ao carregar histórico!',
      })
    } finally {
      commit('SET_STATE', { historyLoading: false })
    }
  },

  async [UPDATE_STRUCTURE]({ commit, dispatch }) {
    Swal.fire({
      title: 'Atualizando estrutura...',
      showConfirmButton: false,
      allowOutsideClick: false,
      onBeforeOpen: () => {
        Swal.showLoading()
      },
    })

    try {
      const { layout, structure } = getStructureAndLayout(
        state.structureSelected,
      )

      await ProjectStructureService.update({
        id: state.structureSelected,
        body: { data: structure, layout },
      })

      const initialKey = `INITIAL_DATA_${state.structureSelected}`
      localStorage.setItem(initialKey, JSON.stringify(structure))
      Swal.close()

      dispatch(LOAD_STRUCTURE_HISTORY, { id: state.structureSelected })

      Toast().fire({
        icon: 'success',
        title: 'Estrutura atualizada com sucesso!',
      })

      commit('SET_MODIFIED_REPORT', false)
      dispatch('reportType/LOAD_REPORT_TYPE', null, { root: true })
    } catch (error) {
      Toast().fire({
        icon: 'error',
        title: 'Erro ao atualizar estrutura!',
      })
    }
  },

  async [ROLLBACK]({ commit, dispatch }, { id }) {
    Swal.fire({
      title: 'Revertendo estrutura...',
      showConfirmButton: false,
      allowOutsideClick: false,
      onBeforeOpen: () => {
        Swal.showLoading()
      },
    })

    try {
      await ProjectStructureService.rollback(id)

      const key = `DATA_${state.structureSelected}`
      const initialKey = `INITIAL_DATA_${state.structureSelected}`

      localStorage.removeItem(key)
      localStorage.removeItem(initialKey)

      await dispatch(LOAD_STRUCTURE_HISTORY, { id: state.structureSelected })
      await dispatch(LOAD_STRUCTURE, { id: state.structureSelected })
      Swal.close()
    } catch (error) {
      Toast().fire({
        icon: 'error',
        title: 'Erro ao reverter estrutura!',
      })
    }
  },
}

function jsonToTreeView(dados) {
  let init = 0

  const fieldsToChildren = function (fields) {
    if (fields && fields.length) {
      fields.forEach((c, index) => {
        c.treeViewId = init++
        c.treeViewChildren = []
        if (c.title) {
          if (c?.type === 'form') {
            c.treeViewText = `${index + 1} - ${c.title}`
          } else {
            c.treeViewText = c.title
          }
        } else if (c.label) {
          c.treeViewText = c.label
        }
        if (c.fields && c.fields.length) {
          c.treeViewChildren = fieldsToChildren(c.fields)
        }

        if (c.fields1 && c.fields1.length) {
          c.treeViewChildren.push(...fieldsToChildren(c.fields1))
        }
      })
    }
    return fields
  }

  if (dados && dados.length) {
    dados.forEach(p => {
      p.treeViewId = init++
      p.treeViewText = p.title
      p.treeViewChildren = []
      if (p.steps && p.steps.length) {
        p.treeViewChildren = fieldsToChildren(p.steps)
      }
    })
  }
  return dados
}

function getStructureAndLayout(id) {
  const key = `DATA_${id}`
  const keyLayout = `LAYOUT_PDF_${id}`

  const structure = JSON.parse(localStorage.getItem(key))
  const layout = JSON.parse(localStorage.getItem(keyLayout))

  return { structure, layout }
}

function saveStructure(id, structure) {
  const key = `DATA_${id}`
  localStorage.setItem(key, JSON.stringify(structure))
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
