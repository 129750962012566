import axios from 'axios'
import { ENV_APP } from '../../../../env'

export default {
  async create({ body }) {
    return axios.post(`/projectstructure`, {
      ...body,
      projectId: ENV_APP.ProjectID,
    })
  },

  async update({ id, body }) {
    return axios.put(`/projectstructure/${id}`, body)
  },

  async rollback(id) {
    return axios.get(`/structure/${id}/rollback`)
  },

  find({ id }) {
    const projectID = ENV_APP.ProjectID

    return axios.get(`/projectstructure/${projectID}/structure/${id}`)
  },
}
