<template>
  <v-data-table
    :headers="headers"
    :items="valueFiltered"
    :search="search"
    no-results-text="Nenhum resultado para o texto pesquisado"
    :item-key="itemKey"
    :single-expand="singleExpand"
    :show-expand="showExpand"
    :expanded.sync="expanded"
    calculate-widths
    fixed-header
    :items-per-page="itemsPerPage"
    class="elevation-1 mt-2"
  >
    <template v-slot:top>
      <v-row class="jus" align="center">
        <v-col cols="12" sm="6" lg="4">
          <v-text-field
            v-model="search"
            class="ma-2"
            append-icon="mdi-magnify"
            color="primary"
            label="Pesquisar"
            solo
            hide-details
            clearable
            clear-icon="mdi-close"
            @change="$emit('update:search', search)"
          />
        </v-col>
        <div>
          <slot name="filter"> </slot>
        </div>

        <div>
          <slot name="print"> </slot>
        </div>

        <div style="margin-left: auto">
          <slot name="btnAddFazenda" />
        </div>

        <div class="mx-5 my-2">
          <slot name="btnCadastro" />
        </div>
      </v-row>
    </template>
    <template
      v-for="(coluna, indexHeader, indexFilter) in filters"
      v-slot:[`header.${indexHeader}`]="{ header }"
    >
      {{ header.text }}
      <v-menu
        :key="indexHeader"
        v-model="menuFilter[indexFilter]"
        offset-y
        :close-on-content-click="false"
        style="border-radius: 20px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-badge
              v-if="filters[header.value].length"
              bottom
              color="red"
              dot
              offset-x="10"
              offset-y="10"
              overlap
            >
              <v-icon small color="red"> mdi-filter-variant-plus </v-icon>
            </v-badge>
            <v-icon v-else small> mdi-filter-variant </v-icon>
          </v-btn>
        </template>
        <div style="background-color: white; width: 350px">
          <v-list>
            <v-list-item>
              <v-autocomplete
                v-if="menuFilter[indexFilter]"
                v-model="filters[header.value]"
                multiple
                dense
                auto-select-first
                clearable
                chips
                small-chips
                deletable-chips
                color="primary"
                :items="optionsFilter(header.value)"
                append-icon="mdi-filter"
                :label="`Informe o filtro por: ${header.text}:`"
                hide-details
              >
                <template v-slot:selection="{ item, index }">
                  <v-chip v-if="index < 4" small class="caption">
                    <span>
                      {{ item }}
                    </span>
                  </v-chip>
                  <span v-if="index === 4" class="grey--text caption">
                    (+{{ filters[header.value].length - 4 }} outros)
                  </span>
                </template>
              </v-autocomplete>
            </v-list-item>
          </v-list>
        </div>
      </v-menu>
    </template>

    <template v-slot:[`item.actions`]="{ item }">
      <div style="display: flex !important; flex-direction: row">
        <v-icon
          v-for="(action, index) in actions"
          :key="index"
          small
          :title="action.title"
          :color="action.color"
          class="mr-1"
          @click="action.click(item)"
        >
          {{ action.icon }}
        </v-icon>

        <div v-for="(action, index) in actions" :key="index + 'div'">
          <v-checkbox
            v-if="action.title === 'checkbox'"
            :key="index + 'checkbox'"
            v-model="item.checked"
            dense
            @click="action.click(item)"
          ></v-checkbox>
        </div>
      </div>
    </template>
    <template v-for="slot in slots" v-slot:[slot]="{ item }">
      <slot :name="slot" :item="item" />
    </template>
    <template v-slot:no-data>
      <v-row justify="center">
        {{ noDataMsg }}
      </v-row>
    </template>
    <template v-slot:expanded-item="{ item }">
      <slot name="expanded-item" :item="item" :headers="headers" />
      <v-checkbox v-model="item.checked" class="mr-1" />
    </template>
  </v-data-table>
</template>

<script>
import Vue from 'vue'
import { mapState } from 'vuex'
import { getProperty } from '../../utils/objectUtils'

const style = document.createElement('style')
style.type = 'text/css'

const applyCallback = (el, vnode) => {
  const columns = vnode.componentOptions?.propsData?.headers
  const showSelect =
    vnode.componentOptions?.propsData?.showSelect !== undefined || false

  const fixedIndexes = []
  columns?.forEach((column, columnIdx) => {
    if (column.fixed) {
      fixedIndexes.push(columnIdx + (showSelect ? 2 : 1))
    }
  })
  if (showSelect) {
    fixedIndexes.push(1)
  }

  const uniqId =
    Date.now().toString(36) + Math.random().toString(36).substring(2)
  el.setAttribute('v-fixed-columns', uniqId)

  let css = `[v-fixed-columns="${uniqId}"] tbody > tr:hover > td,
  [v-fixed-columns="${uniqId}"] tbody > tr.v-data-table__selected > td {
    background: inherit !important;
  }`

  fixedIndexes?.forEach((index, indexIdx) => {
    css += `[v-fixed-columns="${uniqId}"] tbody > tr > td:nth-child(${index}), [v-fixed-columns="${uniqId}"] thead > tr > th:nth-child(${index})`
    if (indexIdx < fixedIndexes.length - 1) {
      css += ','
    }
  })

  css +=
    '{ position: sticky !important; position: -webkit-sticky !important; left: 0; z-index: 3; background: white;}'

  fixedIndexes?.forEach((index, indexIdx) => {
    css += `[v-fixed-columns="${uniqId}"] > tbody > tr > td:nth-child(${index}), [v-fixed-columns="${uniqId}"] > .v-data-table__wrapper > table > thead > tr > th:nth-child(${index})`

    if (indexIdx < fixedIndexes.length - 1) {
      css += ','
    }
  })

  css += '{ z-index: 4;}'

  let left = 0

  columns?.forEach((column, columnIdx) => {
    if (column.fixed) {
      const index = columnIdx + (showSelect ? 2 : 1)
      css += `[v-fixed-columns="${uniqId}"] tbody > tr > td:nth-child(${index}), [v-fixed-columns="${uniqId}"] thead > tr > th:nth-child(${index}) { left: ${
        left + (showSelect ? 64 : 0)
      }px !important;}`
      left += (column.width || 0) + left
    }
  })

  style.innerHTML = css
  document.head.appendChild(style)
}

const unbindCallback = el => {
  style.innerHTML = ''
  el.removeAttribute('v-fixed-columns')
  style.remove()
}

Vue.directive('fixed-columns', {
  bind: applyCallback,
  componentUpdated: applyCallback,
  unbind: unbindCallback,
})
export default {
  name: 'List',
  emits: ['clicked'],
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    itemKey: {
      type: String,
      default: 'id',
    },
    headers: {
      type: Array,
      default: () => [],
    },
    actions: {
      type: Array,
      default: () => [],
    },
    noDataMsg: {
      type: String,
      default: 'Nenhum item encontrado.',
    },
    singleExpand: {
      type: Boolean,
      default: false,
    },
    showExpand: {
      type: Boolean,
      default: false,
    },
    slots: {
      type: Array,
      default: () => [],
    },
    searchInit: {
      type: String,
      default: null,
    },
    itemsPerPage: {
      type: Number,
      default: 10,
    },
  },
  data() {
    return {
      search: this.searchInit,
      expanded: [],
      filterOpts: [],
      filters: '',
      path: this.$route.path,
      headerName: [],
      menuFilter: [],
    }
  },
  computed: {
    ...mapState(['crud_filter']),
    valueFiltered() {
      let data = this.value.filter(d => {
        return Object.keys(this.filters).every(f => {
          return (
            this.filters[f].length < 1 ||
            this.filters[f].includes(getProperty(d, f))
          )
        })
      })
      this.$root.$emit('updateFilter', data)
      return data
    },
    activeHeaders() {
      return this.headers.filter(header => header.active)
    },
  },
  watch: {
    expanded: function () {
      this.$emit('clicked', this.expanded)
    },

    filters: {
      handler: function () {
        const object = new Object()
        object[this.path] = this.filters

        this.$store.commit('SET_CRUD_FILTER', {
          ...this.$store.state.crud_filter,
          ...object,
        })
      },
      deep: true,
    },
  },
  created() {
    this.filterOpts = []
    let objetoFilter = '{'
    let objetoHeader = '{'
    this.headers.map(header => {
      if (header.filterable) {
        this.filterOpts.push({
          value: '',
          key: header.value,
          checked: false,
          text: header.text,
          unidade: [],
        })
        objetoFilter += `"${header.value}": [],`
      }

      if (header.groupable === undefined) {
        objetoHeader += `"${header.value}": false,`
      } else {
        objetoHeader += `"${header.value}": ${header.groupable},`
      }
    })
    if (objetoFilter.length > 1) {
      this.filters = JSON.parse(
        objetoFilter.substring(0, objetoFilter.length - 1) + '}',
      )

      if (
        this.$store.state.crud_filter &&
        this.$store.state.crud_filter[this.path]
      ) {
        const crud_filter = this.$store.state.crud_filter[this.path]

        this.filters = crud_filter
      }
    }

    if (objetoFilter.length > 1) {
      this.filters = JSON.parse(
        objetoFilter.substring(0, objetoFilter.length - 1) + '}',
      )
    }

    this.headerName = JSON.parse(
      objetoHeader.substring(0, objetoHeader.length - 1) + '}',
    )

    this.menuFilter = Object.keys(this.headerName).map(
      element => element === true,
    )
  },

  methods: {
    filter(data) {
      this.$emit('filter', data)
    },
    optionsFilter(key) {
      const options = []
      this.value.map(item => {
        const hv = getProperty(item, key)
        if (hv !== '' && !options.filter(option => option === hv).length)
          options.push(hv)
      })
      options.sort()
      return options
    },
  },
}
</script>
